import React from 'react'


import image1Mobile from '../assets/ShyCharm.png'

const Logo = ({w,h}) => {
  return (
    <img
      src={image1Mobile}  // The path to your logo image in the public folder
      width={w}
      height={h}
      alt="Logo"
    />
  )
}

export default Logo