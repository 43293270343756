import React from 'react'
import CategoryList from '../components/CategoryList'
import BannerProduct from '../components/BannerProduct'
import HorizontalCardProduct from '../components/HorizontalCardProduct'
import VerticalCardProduct from '../components/VerticalCardProduct'

const Home = () => {
  return (
    <div>
       <CategoryList />
      <BannerProduct />

      {/* Horizontal Card Products */}
      <HorizontalCardProduct category={"watches"} heading={"Popular's Watches"} />
      <HorizontalCardProduct category={"saree"} heading={"Popular's Saree"} />

      {/* Vertical Card Products */}
      <VerticalCardProduct category={"clothing"} heading={"Clothing"} />
      <VerticalCardProduct category={"shoes"} heading={"Shoes"} />
      <VerticalCardProduct category={"handbags"} heading={"Handbags"} />
      <VerticalCardProduct category={"sunglasses"} heading={"Sunglasses"} />
      <VerticalCardProduct category={"jewelry"} heading={"Jewelry"} />
      <VerticalCardProduct category={"belts"} heading={"Belts"} />
      <VerticalCardProduct category={"hats-caps"} heading={"Hats & Caps"} />
      <VerticalCardProduct category={"scarves"} heading={"Scarves"} />
    </div>
  )
}

export default Home