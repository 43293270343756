const displayINRCurrency = (num) => {
    const formatter = new Intl.NumberFormat('en-IN',{
        style : "currency",
        currency : 'INR',
        minimumFractionDigits : 2
    })

    return formatter.format(num)

}

export default displayINRCurrency

// 78818e259564ed2d0b59e2e7134804a9-79295dd0-cacbb3b3