const productCategory = [
    { id: 1, label: "Watches", value: "watches" },
    { id: 2, label: "Saree", value: "saree" },
    { id: 3, label: "Clothing", value: "clothing" },
    { id: 4, label: "Shoes", value: "shoes" },
    { id: 5, label: "Handbags", value: "handbags" },
    { id: 6, label: "Sunglasses", value: "sunglasses" },
    { id: 7, label: "Jewelry", value: "jewelry" },
    { id: 8, label: "Belts", value: "belts" },
    { id: 9, label: "Hats & Caps", value: "hats-caps" },
    { id: 10, label: "Scarves", value: "scarves" },
]


export default productCategory